import React,{useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './LoginStyle.css';
import axios from 'axios'
// import Video from './Video';

export default function Login() {
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
    
    const navigate = useNavigate()

    const Submit = (e)=> {
      e.preventDefault();
      axios.post('http://localhost:2024/endpoint/', {email, password})
      .then(res => { 
        if(res.data==="exist"){
        navigate('/break-bulk',{state:{id:email}})
      }
      else if(res.data==="notexist"){
      alert("Email is wrong")
      } 

  })
  .catch(e=>{
    alert("wrong")
    console.log(e);
  })
      .catch(err => console.log(err))

  }
  return (
    <div className="wrapper">
   
    <form action="" onSubmit={Submit}>
      <h1>LOGIN</h1>
      <div className="input-box">
        <input type="email" placeholder="Username" onChange={(e)=>{setEmail(e.target.value)}} required />
        {/* <FaUser className="icon" /> */}
      </div>
      <div className="input-box">
        <input type="password"  placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} required />
        {/* <FaLock className="icon" /> */}
      </div>
      {/* <div className="remember-forgot">
        <label>
          <input type="checkbox" />
          Remember me
        </label>
        <a href="/" onClick={(e) => e.preventDefault()}>
          Forgot password?
        </a>
      </div> */}
      <div class="from-group text-center">
                            <button type="submit" class="btn btn-outline-primary">Submit</button>&nbsp;
                            <Link class="btn btn-outline-danger" to="/" >Go Back</Link>
                         
    
                        </div>
      {/* <button type="submit">LOGIN</button> */}

      {/* <div className="register-link">
        <p>
          Don't have an account?&nbsp;&nbsp;&nbsp;<a href="/video2">Register</a>
         
        </p>
      </div> */}
    </form>
  </div>
  )
}
