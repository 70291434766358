import React from 'react'
import "./TripStyle.css"
import TripData from './TripData'
import Trip1 from "../assets/img13.jpg"
import Trip2 from "../assets/img15.png"
import Trip3 from "../assets/img16.jpeg"
import Trip4 from "../assets/img19.jpg"


export default function Trip() {
  return (
    <div className='trip'>
      <h1 className='text-center'>Service</h1>
      <div className='tripcard'>
        <TripData
        image={Trip1}
        heading ="Ship Agency and Cargo Logistic Services"
        text="Each month, we provide ship husbandry services to our vessels calling at the port of Singapore. This includes many aspects of the agency services like vessel entry/exit clearance, crew sign-on/off, documentation, supervision of stevedores, clearing and delivery of ship’s stores, warehousing, freshwater and bunker arrangements, provisions and in coordinating activities of our principal, shippers, consignees and port authorities."
        />
     

     <TripData
        image={Trip2}
        heading ="Freight Forwarding"
        text="Our well-connected network and the fact that Singapore is a premier hub port of good international standing have enhanced our position in enabling us to meet the varied needs of our customers and their expectation of quality service at the most competitive rate."
        />

<TripData
        image={Trip3}
        heading ="Project Cargo Handling"
        text="Our experienced staff and stevedores are well-equipped to handle cargoes of extreme weight and size, safely and to the satisfaction of our customers."
        />

<TripData
        image={Trip4}
        heading ="Chartering"
        text="Through our subsidiary, EVERETT ASIA LINE PTE. LTD., suitable vessels are fixed on regular basis, in Singapore, to meet the growing needs of our customers, especially those from India."
        />
      </div>
    </div>
  )
}
