import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Manu() {
    const initialData = [
        { id: 1, name: 'John Doe', email: 'john@example.com' },
        { id: 2, name: 'Jane Smith', email: 'jane@example.com' },
        // Add more initial data as needed
      ];
    
      // State to manage table data
      const [data, setData] = useState(initialData);
    
      // State to manage new row inputs
      const [newRow, setNewRow] = useState({ id: '', name: '', email: '' });
    
      // Event handler for inline editing
      const handleEdit = (id, field, value) => {
        const newData = data.map(item => {
          if (item.id === id) {
            return { ...item, [field]: value };
          }
          return item;
        });
        setData(newData);
      };
    
      // Event handler for adding a new row
      const handleAddRow = () => {
        setData([...data, newRow]);
        setNewRow({ id: '', name: '', email: '' }); // Reset new row inputs
      };
  return (
    <div>
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td contentEditable onBlur={e => handleEdit(item.id, 'name', e.target.innerText)}>
              {item.name}
            </td>
            <td contentEditable onBlur={e => handleEdit(item.id, 'email', e.target.innerText)}>
              {item.email}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div>
      <input
        type="text"
        value={newRow.id}
        placeholder="ID"
        onChange={e => setNewRow({ ...newRow, id: e.target.value })}
      />
      <input
        type="text"
        value={newRow.name}
        placeholder="Name"
        onChange={e => setNewRow({ ...newRow, name: e.target.value })}
      />
      <input
        type="text"
        value={newRow.email}
        placeholder="Email"
        onChange={e => setNewRow({ ...newRow, email: e.target.value })}
      />
      <button onClick={handleAddRow}>Add Row</button>
    </div>
  </div>
  )
}
