import React from 'react';
import './Nabar.css';
import { Link } from 'react-router-dom';
// import { MenuItems } from './Menuitems';

export default function Navbar(props) {
    // const [clicked, setClicked] = useState(false);

    // const handleClick = () => {
    //     setClicked(!clicked);
    // };

    return (
        <div className='navbars'>
        <nav className="navbar navbar-expand-lg  bg-body-tertiary  fixed-top navb">
      <div className="container-fluid">

        <h1 className='navbar-logo'> ECL (SINGAPORE) PTE. LTD.</h1>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto">
            <Link className="nav-links active nav-menu" aria-current="page" to="/"><i  className="fa-solid fa-house"></i>{props.Home}</Link>
              
            <div className="nav-item dropdown nav-menu">
              <a className="nav-links dropdown-toggle " href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
               <i className='fa-solid fa-circle-info'></i> {props.About}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/about">{props.Fleet}</Link></li>
                <li><Link className="dropdown-item" to="/pctc">{props.PCTC}</Link></li>
            
              </ul>
            </div>
        
           
            <div className="nav-item dropdown nav-menu">
              <a className="nav-links dropdown-toggle " href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa-regular fa-calendar"></i> {props.More}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/intra-asia">{props.IntraAsia}</Link></li>
                <li><Link className="dropdown-item" to="/Bay-of-Bengal">{props.Bay_Bengal}</Link></li>
                <li><Link className="dropdown-item" to="/pctc2">{props.PCTC2}</Link></li>

              </ul>
            </div>
            <Link className="nav-links nav-menu" to="/contact"><i className='fa-solid fa-address-book'></i>{props.Contact}</Link>

            <Link className="nav-links nav-menu" to="/video"><i className='fa-solid fa-address-book'></i>{props.Login}</Link>
          </div>
        </div>
      </div>
    </nav>
    
        </div>
        // <div>
        //     <nav className="NavbarItems">
        //         <h1 className="navbar-logo">ECL</h1>
        //         <div className="menu-icons" onClick={handleClick}>
        //             <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        //         </div>
        //         <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        //             {MenuItems.map((item, index) => (
        //                 <li key={index}>
        //                     <Link className={item.cName} to={item.url}><i className={item.icon}></i>{item.title}</Link>
        //                 </li>
        //             ))}
        //             <li>
        //                 <button>Sign Up</button>
        //             </li>
        //         </ul>
        //     </nav>
        // </div>
    );
}
