import React, {useState, useEffect} from 'react'
import Navbar1 from './Navbar1'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'


export default function PCTC1() {
    const [vessels, setVessels] = useState([]);
    // const [selectedBreak, setSelectedBreak] = useState('');

  
    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
        try {
          const response = await axios.get("http://localhost:2024/endpoint/get-vesselss");
          setVessels(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    //   const handleVesselChange= (event) => {
    //     setSelectedBreak(event.target.value);
    //   };


    const [vessels_name, setVessels_name] = useState()
    const [dead_weight, setDead_weight] = useState()
    const [built, setBuilt] = useState()
    const [loa, setLOA] = useState()
    const [gross_ton, setGross_ton] = useState()
    const [bale_capacity, setBale_capacity] = useState()
    const [max_lift, setMax_lift] = useState()
    const [rampway, setRampway] = useState()
    const [flag, setFlag] = useState()

    const navigate = useNavigate()

    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2024/endpoint/add-break', {vessels_name, dead_weight, built, loa, gross_ton, bale_capacity, max_lift, rampway, flag})
        .then(result => {console.log(result)
            navigate('/break-bulk')

    })
        .catch(err => console.log(err))

    }
  return (

    <div>
        <Navbar1></Navbar1>
        <div class="py-5">
    <div class="container">
        <div class="row justify-content-center">
    
            <div class="col-md-6">
        
               <div class="card shadow">
          
                <div class="card-header">
                    <h2 className='text-center'>Break Bulk </h2>
                </div>
                <div class="card-body">
                    <form onSubmit={Submit}> 

                    <div className=''>
  <label htmlFor=''>Vessels Name</label>
  <select value={vessels_name} className='form-control'    onChange={(e)=> setVessels_name(e.target.value)}>
    <option value="">Select...</option>
    {vessels.map(vessel => (
      <option key={vessel._id} value={vessel.vessels_name}>{vessel.vessels_name}</option>
    ))}
  </select>
</div>

                   {/* <div className=''>
      <label htmlFor=''>Vessels Name</label>
      <select value={selectedBreak} className='form-control' onChanges={handleVesselChange} onChange={(e)=> setVessels_name(e.target.value)}>
        <option value="">Select...</option>
        {vessels.map(vessel => (
          <option key={vessel._id} value={vessel.vessels_name}>{vessel.vessels_name}</option>
        ))}
      </select>
    </div> */}
      {/* {selectedBreak && <p>Selected break: {selectedBreak}</p>} */}
                    {/* <div className=''>
                        <label for=''>Vessels Name</label>
                        <input type="text" className='form-control' onChange={(e)=> setVessels_name(e.target.value)} />
                    </div> */}

                        {/* <div class="form-group mb-2">
                            <label for="">Category Name</label>
                            <input type="text"  class="form-control" />
    
                        </div>
     */}

<div class="row">
                      <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">Dead Weight</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setDead_weight(e.target.value)} />
    
                        </div>
                        </div>

                        <div class="col-md-4">

                        <div class="form-group mb-3">
                            <label for="">Built</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setBuilt(e.target.value)} />
    
                        </div>
                        </div>
                        </div>
                        
                        <div class="row">
                      <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">L.O.A</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setLOA(e.target.value)} />
    
                        </div>
                        </div>
                        
                        <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">Gross Ton</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setGross_ton(e.target.value)} />
    
                        </div>
                        </div>
                        </div>
                        
                        <div className='row'>
                        <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">Bale Capacity</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setBale_capacity(e.target.value)} />
    
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">Max Lift</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setMax_lift(e.target.value)} />
    
                        </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">Rampway</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setRampway(e.target.value)} />
    
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="form-group mb-3">
                            <label for="">FLAG</label>
                            <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setFlag(e.target.value)}/>
    
                        </div>
                        </div>
                        </div>

                        <div class="from-group text-center">
                            <button type="submit" class="btn btn-success">Save</button>&nbsp;
                            <a class="btn btn-danger" href="break-bulk" >Cancel</a>
                         
    
                        </div>
    </form>

  
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
