import React from 'react';
import "./FooterStyle.css";

export default function Footer() {
  return (
    <div className='footer'>
      <div className='top'>
        <div>
          <h2>ECL (SINGAPORE) PTE. LTD.</h2> {/* Provide meaningful content for the heading */}
        </div>
        <div>
          <a href="/">
            <i className='fa-brands fa-facebook-square'></i>
          </a>
          <a href="/">
            <i className='fa-brands fa-instagram-square'></i>
          </a>
          <a href="/">
            <i className='fa-brands fa-twitter-square'></i>
          </a>
        </div>
      </div>
      <div className='bottom'>
        <div>
          <h4>Footer Content Description</h4> {/* Provide meaningful content for the heading */}
          <a href="/">Home</a>
         
          <a href="/terms">Terms of Use</a>
          <a href="privacy">Privacy Statement</a>
        </div>
      
      </div>
      <p>Contact Us : </p>
      <p>ONEVGA SYSTEM</p>
    </div>
    
  );
}
