import React from 'react'
import Heros from "../components/Heros";
import AboutImg from "../assets/contact-banner.jpg"
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

import Navbar from '../components/Navbar';

export default function Contact() {
  return (
    <>
          <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
     <title>Contact</title>
    
    {/* <Heros /> */}
    <Heros
    cName="hero-mid"
    heroImg={AboutImg}
   
 //    title="PIONEERING FRONTIERS"
 //    buttonText="Travel"
    url="/"
 //    btnClass="show"
    ></Heros>
 <ContactForm></ContactForm>
 

       
  <Footer></Footer>
     </>
  )
}
