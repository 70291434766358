import React from 'react'
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';

export default function Privacy() {
  return (
    <>
    <div>
    <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
    
    <div className='destination'>
            <br></br>
        <h1 className='text-center' style={{ color: 'blue', textAlign: 'center' }}>Privacy Statement</h1>
  <br></br>
        <div >
        <p>ECL Singapore is committed to protecting your privacy and developing technology
        that gives you the most powerful and safe online experience. This Statement of Privacy
        applies to the ECL Singapore site and governs data collection and usage.
        By using the ECL Singapore site, you consent to the data practices described
        in this statement.</p>

        <h5><span class="SubHead">Collection of your Personal Information</span></h5>
        <p>ECL Singapore collects personally identifiable information, such as your email
        address, name, home or work address or telephone number. ECL Singapore also
        collects anonymous demographic information, which is not unique to you, such as
        your ZIP code, age, gender, preferences, interests and favorites.</p>
        <p>There is also information about your computer hardware and software that is automatically
        collected by ECL Singapore. This information can include: your IP address,
        browser type, domain names, access times and referring website addresses. This
        information is used by ECL Singapore for the operation of the service, to
        maintain quality of the service, and to provide general statistics regarding use
        of the ECL Singapore site.</p>
        <p>Please keep in mind that if you directly disclose personally identifiable information
        or personally sensitive data through ECL Singapore public message boards,
        this information may be collected and used by others. Note: ECL Singapore
        does not read any of your private online communications.</p>
        <p>ECL Singapore encourages you to review the privacy statements of Web sites
        you choose to link to from ECL Singapore so that you can understand how those
        Web sites collect, use and share your information. ECL Singapore is not responsible
        for the privacy statements or other content on Web sites outside of the ECL Singapore
        and ECL Singapore family of Web sites.</p>

        <h5><span class="SubHead">Use of your Personal Information</span></h5>
        <p>ECL Singapore collects and uses your personal information to operate the ECL Singapore
        Web site and deliver the services you have requested. ECL Singapore also uses
        your personally identifiable information to inform you of other products or services
        available from ECL Singapore and its affiliates. ECL Singapore may also
        contact you via surveys to conduct research about your opinion of current services
        or of potential new services that may be offered.</p>
        <p>ECL Singapore does not sell, rent or lease its customer lists to third parties.
        ECL Singapore may, from time to time, contact you on behalf of external business
        partners about a particular offering that may be of interest to you. In those cases,
        your unique personally identifiable information (e-mail, name, address, telephone
        number) is not transferred to the third party. In addition, ECL Singapore
        may share data with trusted partners to help us perform statistical analysis, send
        you email or postal mail, provide customer support, or arrange for deliveries. All
        such third parties are prohibited from using your personal information except to
        provide these services to ECL Singapore, and they are required to maintain
        the confidentiality of your information.</p>
        <p>ECL Singapore does not use or disclose sensitive personal information, such
        as race, religion, or political affiliations, without your explicit consent.</p>
        <p>ECL Singapore keeps track of the Web sites and pages our customers visit within
        ECL Singapore, in order to determine what ECL Singapore services are
        the most popular. This data is used to deliver customized content and advertising
        within ECL Singapore to customers whose behavior indicates that they are interested
        in a particular subject area.</p>
        <p>ECL Singapore Web sites will disclose your personal information, without notice,
        only if required to do so by law or in the good faith belief that such action is
        necessary to: (a) conform to the edicts of the law or comply with legal process
        served on ECL Singapore or the site; (b) protect and defend the rights or
        property of ECL Singapore; and, (c) act under exigent circumstances to protect
        the personal safety of users of ECL Singapore, or the public.</p>

        <h5><span class="SubHead">Use of Cookies</span></h5>
        <p>The ECL Singapore Web site use "cookies" to help you personalize your online
        experience. A cookie is a text file that is placed on your hard disk by a Web page
        server. Cookies cannot be used to run programs or deliver viruses to your computer.
        Cookies are uniquely assigned to you, and can only be read by a web server in the
        domain that issued the cookie to you.</p>
        <p>One of the primary purposes of cookies is to provide a convenience feature to save
        you time. The purpose of a cookie is to tell the Web server that you have returned
        to a specific page. For example, if you personalize ECL Singapore pages, or
        register with ECL Singapore site or services, a cookie helps ECL Singapore
        to recall your specific information on subsequent visits. This simplifies the process
        of recording your personal information, such as billing addresses, shipping addresses,
        and so on. When you return to the same ECL Singapore Web site, the information
        you previously provided can be retrieved, so you can easily use the ECL Singapore
        features that you customized.</p>
        <p>You have the ability to accept or decline cookies. Most Web browsers automatically
        accept cookies, but you can usually modify your browser setting to decline cookies
        if you prefer. If you choose to decline cookies, you may not be able to fully experience
        the interactive features of the ECL Singapore services or Web sites you visit.</p>

        <h5><span class="SubHead">Security of your Personal Information</span></h5>
        <p>ECL Singapore secures your personal information from unauthorized access,
        use or disclosure. ECL Singapore secures the personally identifiable information
        you provide on computer servers in a controlled, secure environment, protected from
        unauthorized access, use or disclosure. When personal information (such as a credit
        card number) is transmitted to other Web sites, it is protected through the use
        of encryption, such as the Secure Socket Layer (SSL) protocol.</p>

        <h5><span class="SubHead">Changes to this Statement</span></h5>
        <p>ECL Singapore will occasionally update this Statement of Privacy to reflect
company and customer feedback. ECL Singapore encourages you to periodically 
        review this Statement to be informed of how ECL Singapore is protecting your
        information.</p>

        <h5><span class="SubHead">Contact Information</span></h5>
        <p>ECL Singapore welcomes your comments regarding this Statement of Privacy.
        If you believe that ECL Singapore has not adhered to this Statement, please
        contact ECL Singapore at <a href="mailto:sunil@codeswork.com">sunil@codeswork.com</a>.
        We will use commercially reasonable efforts to promptly determine and remedy the
        problem.</p>

            </div>
            </div>
        </div>
   
    <Footer></Footer>
    </>
  )
}
