import React from 'react'
import './VideoStyle.css';
import videoBg from "../assets/videoBg.mp4";
import Login from './Login';
// import Signup from './Signup';

export default function Video() {
  return (
    <div className="main">
      <Login/>
      {/* <Signup /> */}
    <div className="overlay"></div>
    <video className="video-bg" autoPlay muted loop playsInline>
      <source src={videoBg} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
  )
}
