import React from 'react'
import Ship from '../assets/img12.jpg'
import Ship1 from '../assets/img11.jpg'
import "./DestinationStyle.css"
export default function Destination() {
  return (
    <div className='destination'>
        <h1 className='text-center'>About Us</h1>
      <div className='first-desc'>
        <div className='des-text'>
         
            <h2>Ship </h2>
     
            <p >Our parent office, <b>EASTERN CAR LINER LTD</b>, Japan (<a href="http://www.ecl.co.jp">http://www.ecl.co.jp</a>) was established in 1977, primarily to provide sea transport service to South-East Asia with the positive slogan, ‘Pioneering Frontiers’.</p>
        <p><b>EASTERN CAR LINER LTD</b>, Japan operates a fleet of PCTC and Multipurpose vessels geared up to 150 tons with more than 50 sailings per month, from Japan to China, Korea, South-East Asia, US west coast, India and Persian Gulf.</p>
        <p><b>ECL (SINGAPORE) PTE LTD</b> as operators, provide a pendular service from South-East Asia/China up to the Indian Ocean. With an extensive network, <b>ECL (SINGAPORE) PTE LTD</b> also offers time-bound, regular transhipment sailings from Singapore to nearby destinations.</p>
       
      
        </div>

        <div className='image'>
<img alt="img" src={Ship} />
<img alt="img" src={Ship1} />
        </div>
      </div>
    </div>
  )
}
