import React, {useState} from 'react'
import Navbar1 from './Navbar1'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

export default function Vessels() {
    const [vessels_name, setVessels_name] = useState()
    const navigate = useNavigate()
    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2024/endpoint/add-vessels', {vessels_name})
        .then(result => {console.log(result)
            navigate('/list-vessels')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
       <Navbar1></Navbar1>
        <div class="py-5">
    <div class="container">
        <div class="row justify-content-center">
    
            <div class="col-md-6">
        
               <div class="card shadow">
          
                <div class="card-header">
                    <h2 className='text-center'>Vessels </h2>
                </div>
                <div class="card-body">
                    <form   onSubmit={Submit}> 
                    <div className=''>
                        <label for=''>Vessels Name</label>
                        <input type="text" className='form-control' onChange={(e)=> setVessels_name(e.target.value)} />
                    </div>
<br></br>
                       



                        <div class="from-group text-center">
                            <button type="submit" class="btn btn-success">Save</button>&nbsp;
                            <a class="btn btn-danger" href="list-vessels" >Cancel</a>
                         
    
                        </div>
    </form>

  
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}
