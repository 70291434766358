import React, { useEffect, useState } from "react";
import Navbar1 from "./Navbar1";
import "./Navbar1Style.css";
import axios from "axios";
import { Link } from "react-router-dom";
import './ListAsiaStyle.css';
export default function ListHaiduong() {
    const [glanz_coral, setGlanz_coral] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:2024/endpoint/get-haiduongg")
        .then((result) => setGlanz_coral(result.data))
        .catch((err) => console.log(err));
    }, []);
  
    const handelDelete = (id) => {
      // Prompt the user with a confirmation dialog
      const confirmDelete = window.confirm("Are you sure you want to delete?");
    
      // If the user confirms, proceed with the delete operation
      if (confirmDelete) {
        axios
          .delete("http://localhost:2024/endpoint/soft-haiduong/" + id)
          .then((res) => {
            console.log(res);
            window.location.reload();
          })
          .catch((errr) => console.log(errr));
      }
    };

  return (
     <div>
      <Navbar1 />
      <div>
        <h1 className="head" style={{ color: "black", textAlign: "center" }}>
            HAI DUONG 68 (LOLO)
        </h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-15">
              <div className="card">
                <div className="card-body">
                  <a href="add-haiduong" className="btn btn-secondary float-end">
                    Add Item
                  </a>
                  <br />
                  <br />
                 
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Calling Ports
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                         Est_Date
                      </th>
                     <th  
                     scope="col"
                        style={{ color: "black", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {glanz_coral.map((item) => (
                      <tr key={item._id}>
                       <td>{item.calling_ports}</td>
                <td>{item.est_date}</td>
                      
                        <td>
                        
                          <Link to={`/update-haiduong/${item._id}`} className="btn btn-success">Edit</Link>&nbsp;
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handelDelete(item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
