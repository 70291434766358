import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar1 from './Navbar1'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function UpdateKeio() {
    const {id} = useParams()

    const [vessels_name, setVessels_name] = useState()
    const [calling_ports, setCalling_ports] = useState()

    const [est_date, setEst_date] = useState()

    useEffect(() =>{
        axios.get('http://localhost:2024/endpoint/keio/'+id)
        .then(result => {console.log(result)
            setVessels_name(result.data.vessels_name)
            setCalling_ports(result.data.calling_ports)
            setEst_date(result.data.est_date)
       
        
        })
        .catch(err => console.log(err))
      }, [id])

    const navigate = useNavigate()
    const Update =(e) => {
        e.preventDefault();
            axios.put('http://localhost:2024/endpoint/update-keio/'+id, {vessels_name, calling_ports,  est_date})
            .then(result => {console.log(result)
                navigate('/list-keio')
    
        })
            .catch(err => console.log(err))
    
        }
  return (
    <div>
    <Navbar1></Navbar1>
    <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'> KEIO CORAL(LOLO) </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update}> 
                <div class="row">
                  {/* <div class="col-md-4">
                    <div class="form-group mb-3">
                    <label for=''>Vessels Name</label>
                    <input type="text" className='form-control' value={vessels_name} onChange={(e)=> setVessels_name(e.target.value)} />
                </div>
</div> */}

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Calling Ports</label>
                        <input type="text" formControlName="category_desc" value={calling_ports} class="form-control" onChange={(e)=> setCalling_ports(e.target.value)} />

                    </div>
                    </div>
                  
</div>
                    {/* <div class="form-group mb-2">
                        <label for="">Category Name</label>
                        <input type="text"  class="form-control" />

                    </div>
 */}

<div class="row">

                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for="">Est_Date</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={est_date} onChange={(e)=> setEst_date(e.target.value)} />

                    </div>
                    </div>
               
                  
                    </div>
                    
                   
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/list-keio" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>
</div>

  )
}
