import React, { useEffect, useState } from "react";
import Heros from "../components/Heros";
import AboutImg from "../assets/Bey-of-Bengal.jpg"
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import axios from "axios";
export default function Bay_Bengal() {
  const [precious, setPrecious] = useState([]);
  const [spm_bangkok, setSpm_bangkok] = useState([]);
  const [diamond_star, setDiamond_star] = useState([]);
  const [venus_triumph, setVenus_triumph] = useState([]);
  const [panda, setPanda] = useState([]);
  const [keio_coral, setKeio_coral] = useState([]);
  const [sun_unity36, setSun_unity36] = useState([]);
  const [hosel_crown, setHosel_crown] = useState([]);
  const [manami_coral, setManami_coral] = useState([]);
  const [horizon, setHorizon] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-preciouss")
      .then((result) => setPrecious(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-spmm")
      .then((result) => setSpm_bangkok(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-diamondd")
      .then((result) => setDiamond_star(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-venuss")
      .then((result) => setVenus_triumph(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-pandaa")
      .then((result) => setPanda(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-keioo")
      .then((result) => setKeio_coral(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-sunn")
      .then((result) => setSun_unity36(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-hosell")
      .then((result) => setHosel_crown(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-manamii")
      .then((result) => setManami_coral(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-Horizonn")
      .then((result) => setHorizon(result.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
       <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
    
    {/* <Heros /> */}
    <Heros
    cName="hero-mid"
    heroImg={AboutImg}
   
 //    title="PIONEERING FRONTIERS"
 //    buttonText="Travel"
    url="/"
 //    btnClass="show"
    ></Heros>
    {/* <Contact></Contact> */}
 
    <div style={{backgroundColor : "black"}}>
    <br></br>
    <h1 style={{ color: 'white', textAlign: 'center' }}>Fleet List</h1>
    <br></br>
     <div className="container">
         <div className="row justify-content-center">
           <div className="col-md-30">
             <div className="card">
               <div className="card-body">
 
                 <table class="table table-hover">
                 
   <tbody>
   <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>COSMIC POLARIS (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {precious.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {precious.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>SPM BANGKOK (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {spm_bangkok.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {spm_bangkok.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>DIAMOND STAR (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {diamond_star.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {diamond_star.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>VENUS TRIUMPH (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {venus_triumph.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {venus_triumph.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>21 PANDA (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {panda.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {panda.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>KEIO CORAL (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {keio_coral.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {keio_coral.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>SUN UNITY 36 (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {sun_unity36.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {sun_unity36.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>HOSEL CROWN (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {hosel_crown.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {hosel_crown.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>MANAMI CORAL (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {manami_coral.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {manami_coral.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>


        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>HORIZON 9 (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {horizon.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {horizon.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>


   </tbody>
 </table>
               </div>
             </div>
           </div>
         </div>
       </div>
    </div>
    <br></br>
       
  <Footer></Footer>
     </>
 
  )
}
