import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar1 from './Navbar1'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function VesselsUpdate() {
    const {id} = useParams()
const [vessels_name, setVessels_name] = useState()

const navigate = useNavigate()

useEffect(() =>{
    axios.get('http://localhost:2024/endpoint/vessels/'+id)
    .then(result => {console.log(result)
        setVessels_name(result.data.vessels_name)
   
    
    })
    .catch(err => console.log(err))
  }, [id])

  const Update =(e) => {
    e.preventDefault();
        axios.put('http://localhost:2024/endpoint/update-vessels/'+id, {vessels_name})
        .then(result => {console.log(result)
            navigate('/list-vessels')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
    <Navbar1></Navbar1>
    <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Category </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update} > 
                <div className=''>
                    <label for=''>Vessels Name</label>
                    <input type="text" className='form-control' value={vessels_name}  onChange={(e)=> setVessels_name(e.target.value)} />
                </div>

                   


                    
                   
                  <br></br>

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/list-vessels" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>
</div>


  )
}
