import React, {useState} from 'react'
import Navbar1 from './Navbar1'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";

export default function AddPhoenix() {
    const [vessels_name, setVessels_name] = useState()
    const [calling_ports, setCalling_ports] = useState()

    const [est_date, setEst_date] = useState()



    const navigate = useNavigate()

    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2024/endpoint/add-phoenix', {vessels_name, calling_ports,  est_date})
        .then(result => {console.log(result)
            navigate('/list-phoenix')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
    <Navbar1></Navbar1>
    <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>PHOENIX CORAL (LOLO) </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Submit}> 
                <div class="row">
                  {/* <div class="col-md-4">
                    <div class="form-group mb-3">
                    <label for=''>Vessels Name</label>
                    <input type="text" className='form-control' onChange={(e)=> setVessels_name(e.target.value)} />
                </div>
</div> */}

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Calling Ports</label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setCalling_ports(e.target.value)} />

                    </div>
                    </div>
                  
</div>
                    {/* <div class="form-group mb-2">
                        <label for="">Category Name</label>
                        <input type="text"  class="form-control" />

                    </div>
 */}

<div class="row">

                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for="">Est_Date</label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setEst_date(e.target.value)} />

                    </div>
                    </div>
               
                  
                    </div>
                    
                   
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/list-phoenix" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>
</div>


  )
}
