import React, { useEffect, useState } from "react";
import Heros from "../components/Heros";
import AboutImg from "../assets/Intra-Assia9.jpg"
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import axios from "axios";
import "./IntraStyle.css";

export default function IntraAsia() {
  const [asia, setAsia] = useState([]);
  const [glanz_coral, setGlanz_coral] = useState([]);
  const [seiyo_goddess, setSeiyo_goddess] = useState([]);
  const [hai_duong, setHai_duong] = useState([]);
  const [bright_coral, setBright_coral] = useState([]);
  const [cosmic_oak, setCosmic_oak] = useState([]);
  const [bari_diamond, setBari_diamond] = useState([]);
  const [phoenix_coral, setPhoenix_coral] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-asiaa")
      .then((result) => setAsia(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-glanzz")
      .then((result) => setGlanz_coral(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-seiyoo")
      .then((result) => setSeiyo_goddess(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-haiduongg")
      .then((result) => setHai_duong(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-brights")
      .then((result) => setBright_coral(result.data))
      .catch((err) => console.log(err));
  }, []);
 
  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-cosmicc")
      .then((result) => setCosmic_oak(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-barii")
      .then((result) => setBari_diamond(result.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-phoenixx")
      .then((result) => setPhoenix_coral(result.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
  <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
    
    {/* <Heros /> */}
    <Heros
    cName="hero-mid"
    heroImg={AboutImg}
   
 //    title="PIONEERING FRONTIERS"
 //    buttonText="Travel"
    url="/"
 //    btnClass="show"
    ></Heros>
    {/* <Contact></Contact> */}
 
    <div style={{backgroundColor : "black"}}>
        <br></br>
        <h1 className="head" style={{ color: "#fff", textAlign: "center" }}>
          Fleet List
        </h1>
        <br></br>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-20">
              <div className="card">
                <div className="card-body">
                
                <table class="table table-hover">
    <tbody>
    <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>COSMIC POLARIS (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {asia.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {asia.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>GLANZ CORAL(LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {glanz_coral.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {glanz_coral.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>SEIYO GODDESS(LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {seiyo_goddess.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {seiyo_goddess.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>HAI DUONG 68(LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {hai_duong.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {hai_duong.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>BRIGHT CORAL (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {bright_coral.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {bright_coral.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>COSMIC OAK (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {cosmic_oak.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {cosmic_oak.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>BARI DIAMOND 36 (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {bari_diamond.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {bari_diamond.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

        <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>PHOENIX CORAL (LOLO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {phoenix_coral.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {phoenix_coral.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>

    </tbody>
</table>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <br></br>
      <Footer></Footer> 
  
     </>
 
  )
}
