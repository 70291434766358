import React, { useEffect, useState } from "react";
import Heros from "../components/Heros";
import AboutImg from "../assets/PCTC.jpg"
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import axios from "axios";
import "./IntraStyle.css";

export default function PCTC2() {
    const [malaysia_star, setMalaysia] = useState([]);
    
  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-malaysiaa")
      .then((result) => setMalaysia(result.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
    <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
      
      {/* <Heros /> */}
      <Heros
      cName="hero-mid"
      heroImg={AboutImg}
     
   //    title="PIONEERING FRONTIERS"
   //    buttonText="Travel"
      url="/"
   //    btnClass="show"
      ></Heros>
      {/* <Contact></Contact> */}
      
    <div style={{backgroundColor : "black"}}>
        <br></br>
    <h1 style={{ color: '#fff', textAlign: 'center' }}>Fleet List</h1>
    <br></br>
     <div className="container">
         <div className="row justify-content-center">
           <div className="col-md-30">
             <div className="card">
               <div className="card-body">
 
                 <table class="table table-hover">
                 
   <tbody>
   <tr>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>MALAYSIA STAR (RORO)</b></td>
            <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
        </tr>
        {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
            <td colspan="2">COSMIC POLARIS (LOLO)</td>
        </tr> */}
        <tr>
            <td class="calling-ports">Calling Ports:</td>
            <td class="est-date"> 
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {malaysia_star.map((item) => (
                                <td key={item._id}>{item.calling_ports}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports">Estimated Date:</td>
            <td class="est-date">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {malaysia_star.map((item) => (
                                <td key={item._id}>{item.est_date}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
        </table>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <br></br>
        <Footer></Footer>
      </>
  )
}
