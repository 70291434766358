import React from 'react'
import './Navbar1Style.css';

export default function Navbar1() {
  return (
    <div className='navbar1'>
      
      <nav className="navbar navbar-expand-lg ">
  <div className="container-fluid">
    <a className="navbar-brand head" href="/"><b>ECL (SINGAPORE) PTE. LTD.</b></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Fleet List
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/break-bulk">Break Bulk</a></li>
            <li><a className="dropdown-item" href="/list-pctc">PCTC</a></li>
          
          </ul>
        </li>
       
       
        <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Intra Asia
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-asia">Cosmic Polaris</a></li>
            <li><a className="dropdown-item" href="/list-glanz">Glanz Coral</a></li>
            <li><a className="dropdown-item" href="/list-seiyo">Seiyo Goddess</a></li>
            <li><a className="dropdown-item" href="/list-haiduong">Hai Duong 68</a></li>
            <li><a className="dropdown-item" href="/list-bright">Bright Coral</a></li>
            <li><a className="dropdown-item" href="/list-cosmic">Cosmic OAK</a></li>
            <li><a className="dropdown-item" href="/list-bari">Bari Diamond</a></li>
            <li><a className="dropdown-item" href="/list-phoenix">Phoenix Coral</a></li>
            <li><a className="dropdown-item" href="/list-malaysia">PCTC</a></li>
          
           
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Bay of Bengal
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-precious">Precious Coral</a></li>
            <li><a className="dropdown-item" href="/list-spm">SPM Bangkok</a></li>
            <li><a className="dropdown-item" href="/list-diamond">Diamond Star</a></li>
            <li><a className="dropdown-item" href="/list-venus">Venus Triumph</a></li>
            <li><a className="dropdown-item" href="/list-panda">21 Panda</a></li>
            <li><a className="dropdown-item" href="/list-Keio">Keio Coral</a></li>
            <li><a className="dropdown-item" href="/list-sun">Sun Unity 36</a></li>
            {/* <li><a className="dropdown-item" href="/list-unity">Sun Unity</a></li> */}
            <li><a className="dropdown-item" href="/list-hosei">Hosei Crown</a></li>
            <li><a className="dropdown-item" href="/list-manami">Manami Coral</a></li>
            <li><a className="dropdown-item" href="/list-horizon">Horizon 9</a></li>
          
           
          </ul>
        </li>
      
        <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Master
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-vessels">Vessels</a></li>
          
           
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="video">Logout</a>
        </li>
        
      </ul>
   
    </div>
  </div>
</nav>
    </div>
  )
}
