import React, { useEffect, useState } from "react";
import Navbar1 from "./Navbar1";
import "./Navbar1Style.css";
import axios from "axios";
import { Link } from "react-router-dom";


export default function PCTClist() {
  const [PCTC, setPctc] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-pctcc")
      .then((result) => setPctc(result.data))
      .catch((err) => console.log(err));
  }, []);

  const handelDelete = (id) => {
    // Prompt the user with a confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete?");
  
    // If the user confirms, proceed with the delete operation
    if (confirmDelete) {
      axios
        .delete("http://localhost:2024/endpoint/soft-pctc/" + id)
        .then((res) => {
          console.log(res);
          window.location.reload();
        })
        .catch((errr) => console.log(errr));
    }
  };
  
  return (
    <div>
    <Navbar1 />
    <div>
      <h1 className="head" style={{ color: "black", textAlign: "center" }}>
        Fleet List
      </h1>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-15">
            <div className="card">
              <div className="card-body">
                <a href="add-pctc" className="btn btn-secondary float-end">
                  Add Item
                </a>
                <br />
                <br />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Vessels Name
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Dead Weight
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Built
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Dock
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        L.O.A
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Gross Ton
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Bale Capacity
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Max Lift
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Rampway
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        FLAG
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {PCTC.map((item) => (
                      <tr key={item._id}>
                        <td>{item.vessels_name}</td>
                        <td>{item.dead_weight}</td>
                        <td>{item.built}</td>
                        <td>{item.dock}</td>
                        <td>{item.loa}</td>
                        <td>{item.gross_ton}</td>
                        <td>{item.bale_capacity}</td>
                        <td>{item.max_lift}</td>
                        <td>{item.rampway}</td>
                        <td>{item.flag}</td>
                        <td>
                        
                          <Link to={`/update-pctc/${item._id}`} className="btn btn-success">Edit</Link>&nbsp;
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handelDelete(item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  )
}
