import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar1 from './Navbar1'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function Update() {
const {id} = useParams()
const [vessels_name, setVessels_name] = useState()
const [dead_weight, setDead_weight] = useState()
const [built, setBuilt] = useState()
const [loa, setLOA] = useState()
const [gross_ton, setGross_ton] = useState()
const [bale_capacity, setBale_capacity] = useState()
const [max_lift, setMax_lift] = useState()
const [rampway, setRampway] = useState()
const [flag, setFlag] = useState()

const navigate = useNavigate()

useEffect(() =>{
    axios.get('http://localhost:2024/endpoint/break/'+id)
    .then(result => {console.log(result)
        setVessels_name(result.data.vessels_name)
        setDead_weight(result.data.dead_weight)
        setBuilt(result.data.built)
        setLOA(result.data.loa)
        setGross_ton(result.data.gross_ton)
        setBale_capacity(result.data.bale_capacity)
        setMax_lift(result.data.max_lift)
        setRampway(result.data.rampway)
        setFlag(result.data.flag)
    
    })
    .catch(err => console.log(err))
  }, [id])

  const Update =(e) => {
    e.preventDefault();
        axios.put('http://localhost:2024/endpoint/update-break/'+id, {vessels_name, dead_weight, built, loa, gross_ton, bale_capacity, max_lift, rampway, flag})
        .then(result => {console.log(result)
            navigate('/break-bulk')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
    <Navbar1></Navbar1>
    <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Category </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update} > 
                <div className=''>
                    <label for=''>Vessels Name</label>
                    <input type="text" className='form-control' value={vessels_name}  onChange={(e)=> setVessels_name(e.target.value)} readOnly />
                </div>

                    {/* <div class="form-group mb-2">
                        <label for="">Category Name</label>
                        <input type="text"  class="form-control" />

                    </div>
 */}

<div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Dead Weight</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={dead_weight} onChange={(e)=> setDead_weight(e.target.value)} />

                    </div>
                    </div>

                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for="">Built</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={built} onChange={(e)=> setBuilt(e.target.value)} />

                    </div>
                    </div>
                    </div>
                    
                    <div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">L.O.A</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={loa}  onChange={(e)=> setLOA(e.target.value)} />

                    </div>
                    </div>
                    
                    <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Gross Ton</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={gross_ton} onChange={(e)=> setGross_ton(e.target.value)} />

                    </div>
                    </div>
                    </div>
                    
                    <div className='row'>
                    <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Bale Capacity</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={bale_capacity} onChange={(e)=> setBale_capacity(e.target.value)} />

                    </div>
                    </div>

                    <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Max Lift</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={max_lift} onChange={(e)=> setMax_lift(e.target.value)} />

                    </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">Rampway</label>
                        <input type="text" formControlName="category_desc" class="form-control" value={rampway}  onChange={(e)=> setRampway(e.target.value)} />

                    </div>
                    </div>

                    <div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for="">FLAG</label>
                        <input type="text" formControlName="category_desc" class="form-control"  value={flag} onChange={(e)=> setFlag(e.target.value)} />

                    </div>
                    </div>
                    </div>

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/break-bulk" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>
</div>

  )
}
