import React,{useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './LoginStyle.css';
import axios from 'axios'

export default function Signup() {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
      
      const navigate = useNavigate()
  
      const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2024/endpoint/add-signup', {email, password})
        .then(res => { 
            if(res.data==="exist"){
                alert("User already exists")
            // navigate('/break-bulk',{state:{id:email}})
          }
          else if(res.data==="notexist"){
            navigate('/break-bulk',{state:{id:email}})
        //   alert("User is not logged in")
          } 
    
      })
      .catch(e=>{
        alert("wrong")
        console.log(e);
      })
        .catch(err => console.log(err))
  
    }
  return (
    <div>
       <div className="wrapper">
   
   <form action="" onSubmit={Submit}>
     <h1>Register</h1>
     <div className="input-box">
       <input type="email" placeholder="Username" onChange={(e)=>{setEmail(e.target.value)}} required />
       {/* <FaUser className="icon" /> */}
     </div>
     <div className="input-box">
       <input type="password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} required />
       {/* <FaLock className="icon" /> */}
     </div>
     <div className="remember-forgot">
       <label>
         <input type="checkbox" />
         Remember me
       </label>
       <a href="/" onClick={(e) => e.preventDefault()}>
         Forgot password?
       </a>
     </div>
     <div class="from-group text-center">
                           <button type="submit" class="btn btn-outline-primary">Submit</button>&nbsp;
                           <Link class="btn btn-outline-danger" to="/video" >Go Back</Link>
                        
   
                       </div>
   

   
   </form>
 </div>
    </div>
  )
}
