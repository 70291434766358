import React,{useState, useEffect} from 'react'
import Heros from "../components/Heros";
import AboutImg from "../assets/img7.jpg"
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import axios from "axios";
// import { Link } from "react-router-dom";

export default function About() {
  const [break_bulk, setBreak_bulk] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:2024/endpoint/get-breaks")
      .then((result) => setBreak_bulk(result.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
       <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
    
   {/* <Heros /> */}
   <Heros
   cName="hero-mid"
   heroImg={AboutImg}
  
//    title="PIONEERING FRONTIERS"
//    buttonText="Travel"
   url="/"
//    btnClass="show"
   ></Heros>
   {/* <Contact></Contact> */}

   <div style={{backgroundColor : "black"}}>
    <br></br>
        <h1 className="head" style={{ color: "#fff", textAlign: "center" }}>
          Fleet List
        </h1>
        <br></br>
        <div className="container" >
          <div className="row justify-content-center" >
            <div className="col-md-12">
              <div className="card" >
                <div className="card-body" >
                 
              
                  <table className="table table-hover" >
                    <thead >
                      <tr>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center",    backgroundColor: "#36304a" }}
                        >
                          Vessels Name
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a"}}
                        >
                          Dead Weight
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a" }}
                        >
                          Built
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a"  }}
                        >
                          L.O.A
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a" }}
                        >
                          Gross Ton
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a"  }}
                        >
                          Bale Capacity
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a" }}
                        >
                          Max Lift
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor: "#36304a"  }}
                        >
                          Rampway
                        </th>
                        <th
                          scope="col"
                          style={{ color: "#fff", textAlign: "center", backgroundColor:  "#36304a"  }}
                        >
                          FLAG
                        </th>
                        
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {break_bulk.map((item) => (
                        <tr key={item._id}>
                          <td>{item.vessels_name}</td>
                          <td>{item.dead_weight}</td>
                          <td>{item.built}</td>
                          <td>{item.loa}</td>
                          <td>{item.gross_ton}</td>
                          <td>{item.bale_capacity}</td>
                          <td>{item.max_lift}</td>
                          <td>{item.rampway}</td>
                          <td>{item.flag}</td>
                        
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
     
   <Footer></Footer>
    </>
  )
}
