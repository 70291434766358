import React from 'react'
import './HeroStyles.css';
export default function Heros(props) {
  return (
    <>
  
    <div className={props.cName}>
        <img alt="HerpImg" src={props.heroImg}></img>
        
      
   
    <div className='hero-text'>
        <h1>{props.title}</h1>
       
        <a href={props.url} className={props.btnClass}>{props.buttonText}</a>

    </div>
    </div>
    </>
  )
}
