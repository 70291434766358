// import Heros from "./components/Nav";

import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Heros from "../components/Heros";
import HomeImg from "../assets/img6.jpg"

import Trip from "../components/Trip";
import Navbar from "../components/Navbar";

// import Contact from "./Contact";

function Home() {
    return (
        <>
       <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal" Login="Login" PCTC2="PCTC"></Navbar>
        {/* <Navbar Home="Home" About="Fleet List" Contact="Enquiry" Fleet="Break Bulk" PCTC="PCTC" More="Schedule" IntraAsia="Intra-Asia" Bay_Bengal="Bay of Bengal"></Navbar> */}
       
   {/* <Heros /> */}
   <Heros
   cName="hero"
   heroImg={HomeImg}
//    heroImg="http://eclspore.sg/portals/0/PCTC-InnerPage-Banner.jpg"
   title="PIONEERING FRONTIERS"
//    buttonText="Travel"
   url="/"
//    btnClass="show"
   ></Heros>
    <Destination></Destination>
    <Trip></Trip>

    <Footer></Footer>
   {/* <Contact></Contact> */}
        </>

    );
}

export default Home;