
import './App.css';

// import Heros from './components/Heros';
import Home from './routes/Home';
import About from './routes/About';
import Service from './routes/Service';
import Contact from './routes/Contact';

import { Routes, Route } from 'react-router-dom';
import PCTC from './routes/PCTC';
import IntraAsia from './routes/IntraAsia';
import BayBengal from './routes/BayBengal';
import Home1 from './Admin/Home1';
import PCTC1 from './Admin/PCTC1';
import Update from './Admin/Update';
import Login from './components/Login';
import Video from './components/Video';
import Vessels from './Admin/Vessels';
import VesselsList from './Admin/VesselsList';
import VesselsUpdate from './Admin/VesselsUpdate';
import PCTCadd from './Admin/PCTCadd';
import PCTClist from './Admin/PCTClist';
import PCTCupdate from './Admin/PCTCupdate';
import Add_asia from './Admin/Add_asia';
import ListAsia from './Admin/ListAsia';
import Manu from './Admin/Manu';
import IntraAsia2 from './Admin/IntraAsia2';
import AsiaUpdate from './Admin/AsiaUpdate';
import Addglanz from './Admin/Addglanz';
import Listglanz from './Admin/Listglanz';
import Updateglanz from './Admin/Updateglanz';
import AddSeiyo from './Admin/AddSeiyo';
import ListSeiyo from './Admin/ListSeiyo';
import UpdateSeiyo from './Admin/UpdateSeiyo';
import Addhaiduong from './Admin/Addhaiduong';
import ListHaiduong from './Admin/ListHaiduong';
import Updatehaiduong from './Admin/Updatehaiduong';
import AddBreak from './Admin/AddBreak';
import ListBright from './Admin/ListBright';
import UpdateBright from './Admin/UpdateBright';
import AddCosmic from './Admin/AddCosmic';
import ListCosmic from './Admin/ListCosmic';
import UpdateCosmic from './Admin/UpdateCosmic';
import AddBari from './Admin/AddBari';
import ListBari from './Admin/ListBari';
import UpdateBari from './Admin/UpdateBari';
import AddPhoenix from './Admin/AddPhoenix';
import ListPhoenix from './Admin/ListPhoenix';
import UpdatePhoenix from './Admin/UpdatePhoenix';
import AddPrecious from './Admin/AddPrecious';
import ListPrecious from './Admin/ListPrecious';
import UpdatePrecious from './Admin/UpdatePrecious';
import AddSPM from './Admin/AddSPM';
import ListSPM from './Admin/ListSPM';
import UpdateSPM from './Admin/UpdateSPM';
import AddDiamond from './Admin/AddDiamond';
import ListDiamond from './Admin/ListDiamond';
import UpdateDiamond from './Admin/UpdateDiamond';
import AddVenus from './Admin/AddVenus';
import ListVenus from './Admin/ListVenus';
import UpdateVenus from './Admin/UpdateVenus';
import AddPanda from './Admin/AddPanda';
import ListPanda from './Admin/ListPanda';
import UpdatePanda from './Admin/UpdatePanda';
import AddKeio from './Admin/AddKeio';
import ListKeio from './Admin/ListKeio';
import UpdateKeio from './Admin/UpdateKeio';
import AddSunUnity from './Admin/AddSunUnity';
import ListSunUnity from './Admin/ListSunUnity';
import UpdateSunUnity from './Admin/UpdateSunUnity';
import AddHoselCrown from './Admin/AddHoselCrown';
import ListHoselCrown from './Admin/ListHoselCrown';
import UpdateHoselCrown from './Admin/UpdateHoselCrown';
import AddManami from './Admin/AddManami';
import LIstManami from './Admin/LIstManami';
import UpdateManami from './Admin/UpdateManami';
import AddHorizon from './Admin/AddHorizon';
import ListHorizon from './Admin/ListHorizon';
import UpdateHorizon from './Admin/UpdateHorizon';
import PCTC2 from './routes/PCTC2';
import AddMalaysia from './Admin/AddMalaysia';
import ListMalaysia from './Admin/ListMalaysia';
import UpdateMalaysia from './Admin/UpdateMalaysia';
import Terms from './routes/Terms';
import Privacy from './routes/Privacy';
import Signup from './components/Signup';
import Video2 from './components/Video2';








function App() {
  return (
    <>
  
    <div className="App">
  
{/* <Heros></Heros> */}
 <Routes>
<Route path="/" element={<Home/>}></Route>
<Route path="/about" element={<About/>}></Route>
<Route path="/service" element={<Service/>}></Route>
<Route path="/pctc" element={<PCTC/>}></Route>
<Route path="/intra-asia" element={<IntraAsia/>}></Route>
<Route path="/Bay-of-Bengal" element={<BayBengal/>}></Route>
<Route path="/contact" element={<Contact/>}></Route>
<Route path="/break-bulk" element={<Home1/>}></Route>
<Route path="tcpwf" element={<PCTC1/>}></Route>
<Route path="/update/:id" element={<Update />}></Route>
<Route path="/video" element={<Video />}></Route>
<Route path="/login" element={<Login />}></Route>
<Route path="/signup" element={<Signup />}></Route>
<Route path="/vessels" element={<Vessels />}></Route>
<Route path="/list-vessels" element={<VesselsList />}></Route>
<Route path="/update-vessels/:id" element={<VesselsUpdate />}></Route>
<Route path="/add-pctc" element={<PCTCadd />}></Route>
<Route path="/list-pctc" element={<PCTClist />}></Route>
<Route path="/update-pctc/:id" element={<PCTCupdate />}></Route>
<Route path="/add-asia" element={<Add_asia />}></Route>
<Route path="/list-asia" element={<ListAsia />}></Route>
<Route path="/manu" element={<Manu />}></Route>
<Route path="/onevega" element={<IntraAsia2 />}></Route>
<Route path="/update-asia/:id" element={<AsiaUpdate />}></Route>
<Route path="/add-glanz" element={<Addglanz />}></Route>
<Route path="/list-glanz" element={<Listglanz />}></Route>
<Route path="/update-glanz/:id" element={<Updateglanz />}></Route>
<Route path="/add-seiyo" element={<AddSeiyo />}></Route>
<Route path="/list-seiyo" element={<ListSeiyo />}></Route>
<Route path="/update-seiyo/:id" element={<UpdateSeiyo />}></Route> 
<Route path="/add-haiduong" element={<Addhaiduong />}></Route>
<Route path="/list-haiduong" element={<ListHaiduong />}></Route>
<Route path="/update-haiduong/:id" element={<Updatehaiduong />}></Route>
<Route path="/add-bright" element={<AddBreak />}></Route>
<Route path="/list-bright" element={<ListBright />}></Route>
<Route path="/update-bright/:id" element={<UpdateBright />}></Route>
<Route path="/add-cosmic" element={<AddCosmic />}></Route>
<Route path="/list-cosmic" element={<ListCosmic />}></Route>
<Route path="/update-cosmic/:id" element={<UpdateCosmic />}></Route>
<Route path="/add-bari" element={<AddBari />}></Route>
<Route path="/list-bari" element={<ListBari />}></Route>
<Route path="/update-bari/:id" element={<UpdateBari />}></Route>
<Route path="/add-phoenix" element={<AddPhoenix />}></Route>
<Route path="/list-phoenix" element={<ListPhoenix />}></Route>
<Route path="/update-phoenix/:id" element={<UpdatePhoenix />}></Route>
<Route path="/add-precious" element={<AddPrecious />}></Route>
<Route path="/list-precious" element={<ListPrecious />}></Route>
<Route path="/update-precious/:id" element={<UpdatePrecious />}></Route>
<Route path="/add-spm" element={<AddSPM />}></Route>
<Route path="/list-spm" element={<ListSPM />}></Route>
<Route path="/update-spm/:id" element={<UpdateSPM />}></Route>
<Route path="/add-diamond" element={<AddDiamond />}></Route>
<Route path="/list-diamond" element={<ListDiamond />}></Route>
<Route path="/update-diamond/:id" element={<UpdateDiamond />}></Route>
<Route path="/add-venus" element={<AddVenus />}></Route>
<Route path="/list-venus" element={<ListVenus />}></Route>
<Route path="/update-venus/:id" element={<UpdateVenus />}></Route>
<Route path="/add-panda" element={<AddPanda />}></Route>
<Route path="/list-panda" element={<ListPanda />}></Route>
<Route path="/update-panda/:id" element={<UpdatePanda />}></Route>
<Route path="/add-keio" element={<AddKeio />}></Route>
<Route path="/list-keio" element={<ListKeio />}></Route>
<Route path="/update-keio/:id" element={<UpdateKeio />}></Route>
<Route path="/add-sun" element={<AddSunUnity />}></Route>
<Route path="/list-sun" element={<ListSunUnity />}></Route>
<Route path="/update-sun/:id" element={<UpdateSunUnity />}></Route>
<Route path="/add-hosel" element={<AddHoselCrown />}></Route>
<Route path="/list-hosei" element={<ListHoselCrown />}></Route>
<Route path="/update-hosel/:id" element={<UpdateHoselCrown />}></Route>
<Route path="/add-manami" element={<AddManami />}></Route>
<Route path="/list-manami" element={<LIstManami />}></Route>
<Route path="/update-manami/:id" element={<UpdateManami />}></Route>
<Route path="/add-horizon" element={<AddHorizon />}></Route>
<Route path="/list-horizon" element={<ListHorizon />}></Route>
<Route path="/update-horizon/:id" element={<UpdateHorizon />}></Route>
<Route path="/pctc2" element={<PCTC2 />}></Route>
<Route path="/add-malaysia" element={<AddMalaysia />}></Route>
<Route path="/list-malaysia" element={<ListMalaysia />}></Route>
<Route path="/update-malaysia/:id" element={<UpdateMalaysia />}></Route>
<Route path="/terms" element={<Terms />}></Route>
<Route path="/privacy" element={<Privacy />}></Route>
<Route path="/video2" element={<Video2 />}></Route>




 </Routes>
    </div>
    </>
  );
}

export default App;
