import React, { useState } from 'react';
import axios from 'axios';

export default function IntraAsia2() {
    const [formData, setFormData] = useState([{ name: '', email: '' }]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    const addInput = () => {
        setFormData([...formData, { name: '', email: '' }]);
    };

    const removeInput = index => {
        const updatedFormData = [...formData];
        updatedFormData.splice(index, 1);
        setFormData(updatedFormData);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            // Send data to backend server
            await axios.post('/api/submitFormData', { formData });
            // Reset form after successful submission
            setFormData([{ name: '', email: '' }]);
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {formData.map((data, index) => (
                    <div key={index}>
                        <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={e => handleChange(index, e)}
                            placeholder="Name"
                        />
                        <input
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={e => handleChange(index, e)}
                            placeholder="Email"
                        />
                        <button type="button" onClick={() => removeInput(index)}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={addInput}>Add</button>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}